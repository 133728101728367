import { Injectable } from "@angular/core";
import { CanLoad, Route, UrlSegment, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { take, switchMap, catchError, map } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { CurrentPlatformService } from "../current-platform.service";
import { CookieService } from "ngx-cookie-service";
import { AuthenticationService } from "./authentication-service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanLoad {
  constructor(
    private router: Router,
    private ngFireAuth: AngularFireAuth,
    private currentPlatformService: CurrentPlatformService,
    private cookieService: CookieService,
    private authService: AuthenticationService,
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.ngFireAuth.authState.pipe(
      take(1),
      switchMap(user => {
        const token = this.cookieService.get("token");
        const isLoggedIn = user !== null && user.emailVerified !== false;

        if (!isLoggedIn && token) {
          return this.authService.signInUsingExchangeToken(token).pipe(
            map(() => {
              console.log("Signed in using token!");
              return true;
            }),
            catchError(error => {
              console.error("Error signing in with token:", error);
              return of(false);
            }),
          );
        }

        const isAuthenticated = isLoggedIn || !!token;

        console.log("AuthGuard isAuthenticated:", isAuthenticated);

        if (!isAuthenticated) {
          return this.handleUnauthenticatedUser();
        }

        return of(true);
      }),
    );
  }

  private handleUnauthenticatedUser(): Observable<boolean> {
    const isAndroid = this.currentPlatformService.isAndroid();
    const isApple = this.currentPlatformService.isApple();

    if (isAndroid || isApple) {
      this.router.navigateByUrl("/slides");
    } else {
      this.router.navigateByUrl("/entry/login");
    }

    return of(false);
  }
}
