import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";

import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AuthenticationService } from "./authentication-service";
import { AccountService } from "src/app/account/account.service";
import { UserHolding } from "src/app/account/account.model";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  urlsToNotUse: Array<string>;

  constructor(
    public ngFireAuth: AngularFireAuth,
    private authService: AuthenticationService,
    private accountService: AccountService,
  ) {
    this.urlsToNotUse = [
      "auth/token/refresh",
      "auth/register",
      "auth/token",
      "auth/emailtoken/.",
      "auth/reset-password",
      "auth/reset-password/.",
    ];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(req.url)) {
      return this.ngFireAuth.idToken.pipe(
        take(1),
        switchMap(token => {
          if (token === null) {
            console.log("No token available");
            this.authService.SignOut();
            return throwError(() => "No token available");
          }
          return this.addTokenAndHoldingId(req, token).pipe(
            switchMap(clonedReq => {
              return next.handle(clonedReq).pipe(
                catchError(error => {
                  if (error instanceof HttpErrorResponse && error.status === 401) {
                    return throwError(() => error);
                  } else {
                    return throwError(() => error);
                  }
                }),
              );
            }),
          );
        }),
      );
    }

    return next.handle(req);
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = "api/";
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of this.urlsToNotUse) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }

  private addTokenAndHoldingId(req: HttpRequest<any>, token: string): Observable<HttpRequest<any>> {
    return this.accountService.selectedUserHoldingGlobal.pipe(
      take(1),
      map((userHolding: UserHolding) => {
        let headers = {
          Authorization: token,
        };
        if (userHolding && userHolding.holding) {
          headers["Selected-Holding-ID"] = userHolding.holding.toString();
        }
        return req.clone({ setHeaders: headers });
      }),
    );
  }
}
