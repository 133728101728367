<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>Limits Reached</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="loading">
  <ion-item lines="none">
    <ion-spinner name="dots"></ion-spinner>
  </ion-item>
</ion-content>

<ion-content class="ion-text-center" *ngIf="!loading">
  <ion-grid>
    <ion-row class="ion-margin-top">
      <ion-col>
        <h1 class="fw-600">Account Limits Reached</h1>
      </ion-col>
    </ion-row>

    <ion-row class="ion-margin-top">
      <ion-col>
        <p>We're pleased you're enjoying using FlockFinder!</p>

        <p *ngIf="currentMembership">
          {{
            limitedField
              ? "You've reached the maximum number of " + limitedField + " you can add on your "
              : "You've reached the account limit for your "
          }}
          {{ currentMembership.planDescription }} plan.
        </p>
        <p>
          {{
            selectedUser?.ownerType !== "owner"
              ? "The owner of the holding can manage the subscription. Please contact them to upgrade the account."
              : "Upgrade your membership today to continue using FlockFinder."
          }}
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="flock-footer">
    <ion-grid>
      <ion-row class="ion-margin-bottom ion-text-center">
        <ion-col>
          <p class="contact-us-text">
            If you need help, contact the team at:<br />
            <a href="mailto:hello@flockfinder.co.uk"> hello&#64;flockfinder.co.uk </a>
          </p>
        </ion-col>
      </ion-row>

      <ion-row class="ion-margin-top" *ngIf="!loading">
        <ion-col>
          <ion-button class="ff-equal-height-btn" (click)="cancel()" color="primary" expand="block">
            Try Again
          </ion-button>
        </ion-col>

        <!-- Owner can manage subscription -->
        <ion-col *ngIf="selectedUser?.ownerType === 'owner'">
          <ion-button
            class="ff-equal-height-btn"
            (click)="redirectToSubscriptionPortal()"
            expand="block"
            color="success"
          >
            <ion-icon slot="start" name="open-outline"></ion-icon>
            Manage Subscription
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
