import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { IonicModule } from "@ionic/angular";
import { UserHolding, UserMembership } from "src/app/account/account.model";
import { AccountService } from "src/app/account/account.service";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-upgrade-subscription",
  templateUrl: "./upgrade-subscription.component.html",
  styleUrls: ["./upgrade-subscription.component.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class UpgradeSubscriptionComponent implements OnInit {
  // input
  @Input() errorMessage: string;

  currentMembership: UserMembership;
  limitedField: string;
  selectedUser: UserHolding;
  loading = false;
  constructor(
    private modalCtrl: ModalController,
    private accountService: AccountService,
  ) {}

  ngOnInit() {
    this.loading = true;
    if (this.errorMessage) {
      if (this.errorMessage.includes("animal")) {
        this.limitedField = "animals";
      } else if (this.errorMessage.includes("treatment")) {
        this.limitedField = "treatments";
      } else if (this.errorMessage.includes("field")) {
        this.limitedField = "fields";
      } else if (this.errorMessage.includes("movement")) {
        this.limitedField = "movements";
      }
    }
    this.accountService.getUserMembership().subscribe(res => {
      this.currentMembership = res;
      this.accountService.selectedUserHoldingGlobal.subscribe((userHolding: UserHolding) => {
        this.selectedUser = userHolding;
        this.loading = false;
      });
    });
  }

  cancel() {
    this.modalCtrl.dismiss(null, "cancel");
  }
  redirectToSubscriptionPortal() {
    this.accountService.openFlockFinderWithToken("payment");
  }
}
