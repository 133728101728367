import { Injectable, NgZone } from "@angular/core";
import { User } from "./user";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { Preferences } from "@capacitor/preferences";
import { NavController } from "@ionic/angular";
import { CurrentPlatformService } from "../current-platform.service";
import { AccountService } from "src/app/account/account.service";
import { AnalyticsService } from "src/app/core/analytics.service";
import { PushNotificationService } from "../notifications/push-notification.service";
import { catchError, from, Observable, of, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  userData: any;
  authServiceUrl = environment.FLOCKFINDER_API_URL;
  constructor(
    private afStore: AngularFirestore,
    private ngFireAuth: AngularFireAuth,
    private navCtrl: NavController,
    private ngZone: NgZone,
    private currentPlatformService: CurrentPlatformService,
    private accountService: AccountService,
    private analyticsService: AnalyticsService,
    private pushNotificationService: PushNotificationService,
    private http: HttpClient,
    private cookieService: CookieService,
  ) {
    this.ngFireAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem("user", JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem("user"));
      } else {
        localStorage.setItem("user", null);
        JSON.parse(localStorage.getItem("user"));
      }
    });
  }

  get user(): User {
    const user = JSON.parse(localStorage.getItem("user"));
    return user;
  }

  // Login in with email/password
  SignIn(email: string, password: string): Promise<any> {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password).then(result => {
      this.analyticsService.setUserIdentifier(result.user.uid);
      return result;
    });
  }

  // Register user with email/password
  RegisterUser(email, password) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
  }

  // Email verification when new user register
  SendVerificationMail() {
    return this.ngFireAuth.currentUser.then(u => u.sendEmailVerification());
  }

  // Recover password
  PasswordRecover(passwordResetEmail) {
    return this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  // Returns true when user is looged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null && user.emailVerified !== false ? true : false;
  }

  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user.emailVerified !== false ? true : false;
  }

  // Auth providers
  AuthLogin(provider) {
    this.accountService.clearSelectedHolding();
    return this.ngFireAuth
      .signInWithPopup(provider)
      .then(result => {
        this.ngZone.run(() => {
          this.navCtrl.navigateForward("manage/home");
        });
        this.SetUserData(result.user);
      })
      .catch(error => {
        window.alert(error);
      });
  }

  // Store user in localStorage
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  // Sign-out
  async SignOut() {
    this.clearCookies();
    await Preferences.remove({ key: "selectedHolding" });
    await localStorage.removeItem("user");
    await this.pushNotificationService.softClearToken();
    this.accountService.clearSelectedHolding();
    const isBrowser: boolean = this.currentPlatformService.isBrowser();
    return this.ngFireAuth.signOut().then(() => {
      if (isBrowser) {
        this.navCtrl.navigateRoot("entry/login");
      } else {
        this.navCtrl.navigateRoot("slides");
      }
    });
  }

  clearCookies() {
    this.cookieService.delete("token");
  }

  signInUsingExchangeToken(token: string): Observable<any> {
    return this.http
      .get(`${this.authServiceUrl}/api/auth/token-exchange/`, {
        headers: { Authorization: token },
        withCredentials: true,
      })
      .pipe(
        switchMap((response: any) => {
          if (response && response.token) {
            return from(this.SignInWithToken(response.token));
          } else {
            console.error("Invalid token response");
            return of(null);
          }
        }),
        catchError(error => {
          console.error("Token exchange failed", error);
          return of(null);
        }),
      );
  }

  // Login with custom token
  SignInWithToken(token: string): Promise<any> {
    return this.ngFireAuth
      .signInWithCustomToken(token)
      .then(result => {
        this.analyticsService.setUserIdentifier(result.user.uid);
        return result;
      })
      .catch(error => {
        throw error;
      });
  }
}
