import { Directive, OnInit, OnDestroy } from '@angular/core';
import { IonRouterOutlet } from '@ionic/angular';
import { PosthogService } from '../posthog.service';

@Directive({
  selector: 'ion-router-outlet'
})
export class PageTrackingDirective implements OnInit, OnDestroy {
  private activeView: any;

  constructor(
    private routerOutlet: IonRouterOutlet,
    private posthog: PosthogService
  ) {}

  ngOnInit() {
    // Subscribe to Ionic's lifecycle events
    this.routerOutlet.activateEvents.subscribe((event) => {
      const component = event as any;
      this.activeView = component;
      
      // Get the component name
      const componentName = component.constructor.name;
      
      // Track page view
      this.posthog.capture('screen_view', {
        screen_name: this.formatComponentName(componentName),
        component: componentName,
        timestamp: new Date().toISOString()
      });

      // Hook into Ionic lifecycle events if they exist
      if (component.ionViewWillEnter) {
        const original = component.ionViewWillEnter;
        component.ionViewWillEnter = () => {
          this.posthog.capture('page_will_enter', {
            screen_name: this.formatComponentName(componentName)
          });
          original.call(component);
        };
      }

      if (component.ionViewDidEnter) {
        const original = component.ionViewDidEnter;
        component.ionViewDidEnter = () => {
          this.posthog.capture('page_did_enter', {
            screen_name: this.formatComponentName(componentName)
          });
          original.call(component);
        };
      }

      if (component.ionViewWillLeave) {
        const original = component.ionViewWillLeave;
        component.ionViewWillLeave = () => {
          this.posthog.capture('page_will_leave', {
            screen_name: this.formatComponentName(componentName)
          });
          original.call(component);
        };
      }

      if (component.ionViewDidLeave) {
        const original = component.ionViewDidLeave;
        component.ionViewDidLeave = () => {
          this.posthog.capture('page_did_leave', {
            screen_name: this.formatComponentName(componentName)
          });
          original.call(component);
        };
      }
    });
  }

  ngOnDestroy() {
    // Clean up if needed
    if (this.activeView) {
      // Restore original methods if necessary
      ['ionViewWillEnter', 'ionViewDidEnter', 'ionViewWillLeave', 'ionViewDidLeave'].forEach(method => {
        if (this.activeView[`original_${method}`]) {
          this.activeView[method] = this.activeView[`original_${method}`];
        }
      });
    }
  }

  private formatComponentName(name: string): string {
    // Convert camelCase to readable format
    // e.g., "HomePageComponent" becomes "Home Page"
    return name
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, str => str.toUpperCase()) // Capitalize first letter
      .replace(/(Page|Component)$/g, '') // Remove Page or Component suffix
      .trim();
  }
}
