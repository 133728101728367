import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Capacitor, PluginListenerHandle } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { AlertController, Platform, ToastController } from "@ionic/angular";
import { take } from "rxjs/operators";

import { Browser } from "@capacitor/browser";
import { ConnectionStatus, Network } from "@capacitor/network";
import { Subscription } from "rxjs";
import { environment } from "../environments/environment";
import { AccountService } from "./account/account.service";
import { CoreService } from "./core/core.service";
import { AuthenticationService } from "./shared/auth/authentication-service";
import { PosthogService } from "./core/posthog.service";

import { register } from "swiper/element/bundle";
register();

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  private _loggedInObserver: Subscription = new Subscription();

  isDesktop: boolean;
  appVersion: string;
  isMobile = false;
  isIOS = false;
  flockFinderOnline = environment.WEBSITE_ADDRESS;
  networkStatus: ConnectionStatus;
  networkListener: Promise<PluginListenerHandle>;

  public flockImg = "/assets/icon/white/sheep-white.svg";

  constructor(
    private platform: Platform,
    private authService: AuthenticationService,
    private router: Router,
    private core: CoreService,
    private accountService: AccountService,
    private alertController: AlertController,
    private toastController: ToastController,
    private posthogService: PosthogService,
  ) {
    this.initializeApp();
    this.core.isDesktopView().subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    });
    this.appVersion = environment.version;

    if (this.platform.is("mobile")) {
      this.isMobile = true;
    }
    if (this.platform.is("ios") && !this.platform.is("mobileweb")) {
      this.isIOS = true;
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable("SplashScreen")) {
        SplashScreen.hide();
      }
      this.core.onResize(this.platform.width());
      // Add PostHog app_launched event
      this.posthogService.capture('app_launched', {
        version: environment.version,
        platform: Capacitor.getPlatform()
      });
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.core.onResize(event.target.innerWidth);
  }

  onLogout() {
    this.authService.SignOut();
  }

  goToFlockFinderWeb() {
    this.openLink(this.flockFinderOnline);
  }

  async goToAsk() {
    await this.core.goToAsk();
  }

  navManage(url: string) {
    this.accountService.selectedUserHoldingGlobal.pipe(take(1)).subscribe(selectedHolding => {
      if (selectedHolding !== null) {
        let navigationExtras: NavigationExtras = {
          state: {
            sourcePage: this.router.url,
            selectedHolding: selectedHolding,
          },
        };
        this.router.navigate([url], navigationExtras);
      } else {
        this.noHoldingSelected();
      }
    });
  }

  noHoldingSelected() {
    this.accountService.userHoldings.pipe(take(1)).subscribe(userHoldings => {
      if (userHoldings.length === 0) {
        this.goToHoldingsAlert();
      } else {
        this.selectHoldingToast();
      }
    });
  }

  async goToHoldingsAlert() {
    const alert = await this.alertController.create({
      message: "To continue you must add a holding to your account",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.router.navigateByUrl("account");
          },
        },
      ],
    });
    await alert.present();
  }

  async selectHoldingToast() {
    const toast = await this.toastController.create({
      message: "Select holding from dropdown.",
      duration: 2000,
      position: "top",
    });
    toast.present();
  }

  public async noNetworkToast(present = true, message = "", offline = false) {
    let buttons = undefined;
    let duration = 3000;
    if (offline === true) {
      buttons = [
        {
          text: "Hide",
          role: "cancel",
        },
      ];
      duration = 3000;
    }
    const toast = await this.toastController.create({
      message: message,
      color: "light",
      buttons: buttons,
      duration: duration,
    });
    if (present) toast.present();
  }

  async openLink(url: string) {
    await Browser.open({ url: url, presentationStyle: "popover" });
  }

  async ngOnInit() {
    // pre-load js element so can use it when offline
    this.noNetworkToast(false);

    // tracking network status changes
    this.networkListener = Network.addListener("networkStatusChange", status => {
      this.networkStatus = status;
      if (status.connectionType == "none") this.noNetworkToast(true, "No internet connection!", true);
    });
    this.networkStatus = await Network.getStatus();
  }
  ngOnDestroy() {
    this._loggedInObserver.unsubscribe();
    Network.removeAllListeners();
  }
}
