import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Preferences } from "@capacitor/preferences";
import { BehaviorSubject, Observable, from, firstValueFrom } from "rxjs";
import { catchError, distinctUntilChanged, map, switchMap, take, tap } from "rxjs/operators";

import { AngularFireAuth } from "@angular/fire/compat/auth";
import { environment } from "../../environments/environment";
import { OnboardingStepsCompleted } from "../manage/home/home.models";
import { CurrentPlatformService } from "../shared/current-platform.service";
import {
  GetHolding,
  HoldingNew,
  HoldingPermissions,
  Profile,
  ReferralInfo,
  UserHolding,
  UserHoldingType,
  UserMembership,
  VanGuidance,
} from "./account.model";
import { Browser } from "@capacitor/browser";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  private _userHoldings = new BehaviorSubject<UserHolding[]>([]);
  private _selectedUserHolding = new BehaviorSubject<UserHolding>(null);
  private _userProfile = new BehaviorSubject<Profile>(null);
  private _userContributorsHoldings = new BehaviorSubject<UserHolding[]>([]);

  get userHoldings() {
    return this._userHoldings.asObservable();
  }

  get selectedUserHoldingGlobal() {
    return this._selectedUserHolding.asObservable().pipe(distinctUntilChanged());
  }

  get userProfile() {
    return this._userProfile.asObservable();
  }

  get contributorHoldings() {
    return this._userContributorsHoldings.asObservable();
  }

  async isNewUser(): Promise<boolean> {
    const isNewUSer = await Preferences.get({ key: "newUser" });
    if (isNewUSer.value === "false") {
      return false;
    } else if (isNewUSer.value === "true") {
      return true;
    }
    return false;
  }

  setSelectedUserHolding(userHolding: UserHolding) {
    const currentHolding = this._selectedUserHolding.value;
    if (currentHolding && currentHolding.holding === userHolding.holding) {
      return Promise.resolve();
    }
    this.updateSelectedHolding(userHolding);
    return Promise.resolve();
  }

  async onboardingStepsProgress(): Promise<OnboardingStepsCompleted> {
    const onboardingSteps = await Preferences.get({ key: "onboardingSteps" });
    if (onboardingSteps.value) {
      return JSON.parse(onboardingSteps.value);
    }
    const onboardingStepsCompleted = new OnboardingStepsCompleted();
    return onboardingStepsCompleted;
  }

  async updateOnboardingStepsProgress(
    step: "step1" | "step2" | "step3" | "step4" | "step5" | "step6" | "step7",
  ): Promise<void> {
    // update step in onboardingSteps json
    return this.onboardingStepsProgress().then(onboardingSteps => {
      onboardingSteps[step] = true;
      Preferences.set({
        key: "onboardingSteps",
        value: JSON.stringify(onboardingSteps),
      });
    });
  }

  async setNotNewUser(value: string = "false"): Promise<void> {
    await Preferences.set({ key: "newUser", value: value });
    if (value === "true") {
      // set all onboarding steps to false
      await Preferences.set({
        key: "onboardingSteps",
        value: JSON.stringify(new OnboardingStepsCompleted(false, false, false, false, false, false, false)),
      });
    }
  }

  clearSelectedHolding() {
    this._selectedUserHolding.next(null);
  }
  clearProfile() {
    this._userProfile.next(null);
  }

  profileServer = environment.FLOCKFINDER_API_URL;
  flockFinderUrl = environment.FLOCKFINDER_URL_PAYMENT;
  constructor(
    private httpClient: HttpClient,
    public ngFireAuth: AngularFireAuth,
    private currentPlatformService: CurrentPlatformService,
  ) {}

  createProfile(profile: Profile): Observable<Profile> {
    return this.httpClient.post<Profile>(`${this.profileServer}/api/v1/profile/`, profile);
  }

  getProfile() {
    return this.ngFireAuth.user.pipe(
      switchMap(user => {
        if (!user) {
          throw new Error("User not found!");
        }

        return this.httpClient.get<Profile>(`${this.profileServer}/api/v1/profile/${user.uid}/`).pipe(
          map((profile: Profile) => {
            return profile;
          }),
          tap(profile => {
            this._userProfile.next(profile);
          }),
        );
      }),
    );
  }

  updateProfile(profile: Profile) {
    return this.ngFireAuth.user.pipe(
      switchMap(user => {
        if (!user) {
          throw new Error("User not found!");
        }

        return this.httpClient.put<Profile>(`${this.profileServer}/api/v1/profile/${user.uid}/`, profile).pipe(
          map((profile: Profile) => {
            return profile;
          }),
          tap(profile => {
            this._userProfile.next(profile);
          }),
        );
      }),
    );
  }

  getHoldingContributors(): Observable<UserHolding[]> {
    return this.httpClient.get<any>(`${this.profileServer}/api/v1/user-owned-holdings/`).pipe(
      tap((userHoldings: UserHolding[]) => {
        this._userContributorsHoldings.next(userHoldings);
      }),
    );
  }

  getUserHoldings() {
    return this.httpClient.get<UserHolding[]>(`${this.profileServer}/api/v1/user-holdings/`).pipe(
      switchMap(userHoldings => {
        this._userHoldings.next(userHoldings);
        return from(this._updateSelectedUserHolding(userHoldings)).pipe(map(() => userHoldings));
      }),
    );
  }

  private _updateSelectedUserHolding(userHoldings: UserHolding[]): Promise<void> {
    const currentHolding = this._selectedUserHolding.value;
    if (!currentHolding) {
      // if no current holding, get the prior holding name and set the selected holding to that
      return this._getPriorHoldingName().then(priorHoldingName => {
        const storedHolding = userHoldings.find(h => h.holdingName === priorHoldingName);
        this._selectedUserHolding.next(storedHolding || this._selectHoldingLogic(userHoldings) || null);
      });
    } else {
      return Promise.resolve();
    }
  }

  _selectHoldingLogic(userHoldings: UserHolding[]): UserHolding {
    const userHolding: UserHolding = userHoldings.find(h => h.ownerType === "owner");
    if (userHolding) {
      return userHolding;
    }
    const userHolding2: UserHolding = userHoldings.find(
      h => h.holdingName !== "FL/OCK/DEMO" && h.permissionGranted === true,
    );
    if (userHolding2) {
      return userHolding2;
    }
    return userHoldings[0];
  }

  deleteUserHolding(userHoldingId: number) {
    return this.httpClient.delete(`${this.profileServer}/api/v1/user-holdings/${userHoldingId}/`).pipe(
      switchMap(() => {
        return this.userHoldings;
      }),
      take(1),
      tap(userHoldings => {
        this._userHoldings.next(userHoldings.filter(b => b.id !== userHoldingId));
        if (userHoldings.length === 1) {
          // last holding deleted
          this._selectedUserHolding.next(<UserHolding>null);
        } else if (this._selectedUserHolding.value !== undefined) {
          this._selectedUserHolding.next(userHoldings.filter(b => b.id !== userHoldingId)[0]);
        }
        Preferences.remove({ key: "selectedHolding" });
      }),
    );
  }

  addHolding(holdingNew: HoldingNew): Observable<UserHoldingType> {
    // adds holding and assigns to user
    const url = `${this.profileServer}/api/v2/holdings/`;
    return this.httpClient.post<any>(url, holdingNew).pipe(
      map(resData => {
        console.log(resData);
        const holdingID = resData.id;
        const userHolding = resData.user_holdings.find(holding => holding.holding === holdingID);
        console.log(userHolding);
        const newData = {
          id: userHolding.id,
          ownerType: userHolding.ownerType,
          user_id: userHolding.user_id,
          holding: userHolding.holding,
          holdingName: userHolding.holdingName,
          userPaid: userHolding.userPaid,
          cancellationDate: userHolding.cancellationDate,
          createdAt: userHolding.createdAt,
          requestedExtensionEndDate: userHolding.requestedExtensionEndDate,
          firstFlockNumber: userHolding.firstFlockNumber,
          lambingDate: userHolding.lambingDate,
          latestVanNumber: userHolding.latestVanNumber,
          permissionPending: userHolding.permissionPending,
          permissionGranted: userHolding.permissionGranted,
          ownerName: userHolding.ownerName,
          ownerEmail: userHolding.ownerEmail,
          holdingAddress: userHolding.holdingAddress,
          holdingLocation: userHolding.holdingLocation,
        };
        return newData;
      }),
    );
  }

  refreshHoldings() {
    Preferences.remove({ key: "selectedHolding" });
    this.clearSelectedHolding();
  }

  updateHolding(holdingNew: HoldingNew, holdingId) {
    // updates holding and assigns to user
    return this.httpClient
      .put(`${this.profileServer}/api/v2/holdings/${holdingId}/`, holdingNew, {
        observe: "response",
      })
      .pipe(
        switchMap(() => {
          return this.getUserHoldings();
        }),
        take(1),
        tap(userHoldings => {
          this._userHoldings.next(userHoldings);
        }),
      );
  }

  updateHoldingAddress(addressId, selectedHoldingId) {
    return this.httpClient
      .put(`${this.profileServer}/api/v1/holdings/${selectedHoldingId}/address/${addressId}/`, {})
      .pipe(
        catchError(error => {
          return error;
        }),
      );
  }

  requestAccessToHolding(holdingId: number): Observable<any> {
    return this.httpClient.post(`${this.profileServer}/api/v1/request-access-to-holding/${holdingId}/`, {});
  }

  getHolding(holdingName: string): Observable<GetHolding[]> {
    return this.httpClient.get<GetHolding[]>(`${this.profileServer}/api/v2/holdings/?holdingName=${holdingName}`);
  }

  updateSelectedHolding(selectedHolding: UserHolding): void {
    this._selectedUserHolding.next(selectedHolding);
    // store
    const data = JSON.stringify({
      id: selectedHolding.id,
      ownerType: selectedHolding.ownerType,
      user_id: selectedHolding.user_id,
      holding: selectedHolding.holding,
      holdingName: selectedHolding.holdingName,
      firstFlockNumber: selectedHolding.firstFlockNumber,
      latestVanNumber: selectedHolding.latestVanNumber,
      holdingAddress: selectedHolding.holdingAddress,
      userPaid: selectedHolding.userPaid,
      cancellationDate: selectedHolding.cancellationDate,
      createdAt: selectedHolding.createdAt,
      lambingDate: selectedHolding.lambingDate,
      holdingLocation: selectedHolding.holdingLocation,
    });
    Preferences.set({ key: "selectedHolding", value: data });
  }

  updateContributorsHoldingPermissions(holdingPermissions: HoldingPermissions, userHoldingId) {
    return this.httpClient
      .patch(`${this.profileServer}/api/v1/user-owned-holdings/${userHoldingId}/`, holdingPermissions, {
        observe: "response",
      })
      .pipe(
        switchMap(() => {
          return this.getHoldingContributors();
        }),
        take(1),
        tap(userHoldings => {
          this._userContributorsHoldings.next([]);
          this._userContributorsHoldings.next(userHoldings);
        }),
      );
  }

  async _getPriorHoldingName(): Promise<string> {
    const storedData = await Preferences.get({ key: "selectedHolding" });
    if (!storedData || !storedData.value) {
      return;
    }
    const parsedData = JSON.parse(storedData.value) as UserHolding;
    return parsedData?.holdingName;
  }

  getFarmPlanLink(membershipPlan: string) {
    const isAndroid = this.currentPlatformService.isAndroid();
    if (isAndroid) {
      return this.httpClient.get<any>(`${this.profileServer}/api/v1/payments/checkout/${membershipPlan}/?android=true`);
    } else {
      return this.httpClient.get<any>(`${this.profileServer}/api/v1/payments/checkout/${membershipPlan}/`);
    }
  }

  getFarmPlanLinkEntry(membershipPlan: string) {
    const isAndroid = this.currentPlatformService.isAndroid();
    if (isAndroid) {
      return this.httpClient.get<any>(
        `${this.profileServer}/api/v1/payments/checkout/entry/${membershipPlan}/?android=true`,
      );
    } else {
      return this.httpClient.get<any>(`${this.profileServer}/api/v1/payments/checkout/entry/${membershipPlan}/`);
    }
  }

  getFarmPlanLinkEntryNoCoupon(membershipPlan: string) {
    const isAndroid = this.currentPlatformService.isAndroid();
    if (isAndroid) {
      return this.httpClient.get<any>(
        `${this.profileServer}/api/v1/payments/checkout/entry/no_coupon/${membershipPlan}/?android=true`,
      );
    } else {
      return this.httpClient.get<any>(
        `${this.profileServer}/api/v1/payments/checkout/entry/no_coupon/${membershipPlan}/`,
      );
    }
  }

  goToBillingPortal() {
    const isAndroid = this.currentPlatformService.isAndroid();
    if (isAndroid) {
      return this.httpClient.get<any>(`${this.profileServer}/api/v1/payments/billing/?android=true`);
    } else {
      return this.httpClient.get<any>(`${this.profileServer}/api/v1/payments/billing/`);
    }
  }

  async openFlockFinderWithToken(page: "payment" | "account" = "payment") {
    const token = await firstValueFrom(this.ngFireAuth.idToken.pipe(take(1)));
    let baseUrl = page === "payment" ? environment.FLOCKFINDER_URL_PAYMENT : environment.FLOCKFINDER_URL_ACCOUNT;
    if (!baseUrl.endsWith("/")) {
      baseUrl += "/";
    }
    const url = `${baseUrl}?token=${token}`;
    await Browser.open({ url });
  }

  getMembershipOptions() {
    return this.httpClient.get<any>(`${this.profileServer}/api/v1/payments/membership-options/`);
  }

  getUserMembership() {
    return this.httpClient.get<UserMembership>(`${this.profileServer}/api/v1/payments/membership-status/`);
  }

  sendErrorReport(errorMessage: string) {
    // send error report if 500 error
    let apiString = `${this.profileServer}/api/v1/send-error-report/?errorMessage=${errorMessage}`;
    return this.httpClient.post<null>(apiString, {});
  }

  sendReferral(refereeEmail: string) {
    // send referral
    let apiString = `${this.profileServer}/api/v1/payments/refer-friend/${refereeEmail}/`;
    return this.httpClient.post<null>(apiString, {});
  }

  logReferral(referralCode: string) {
    // log referral
    let apiString = `${this.profileServer}/api/v1/payments/log-referral/${referralCode}/`;
    return this.httpClient.post<null>(apiString, {});
  }

  getReferralInfo() {
    // get referral info
    let apiString = `${this.profileServer}/api/v1/payments/get-referral-info/`;
    return this.httpClient.get<ReferralInfo>(apiString);
  }
  // a new get request to get van information from /van-guidance
  getVanGuidance(): Observable<VanGuidance[]> {
    return this.httpClient.get<VanGuidance[]>(`${this.profileServer}/api/v1/van-guidance/`);
  }

  formatLambingDateString(lambingDate: string) {
    const weekMonthArr = lambingDate.split(" - ");
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months.indexOf(weekMonthArr[1]) + 1;
    const week = parseInt(weekMonthArr[0].split(" ")[1]);
    return { week: week, month: month };
  }

  formatLambingDateObject(lambingDate: { week: number; month: number }) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return `Week ${lambingDate.week} - ${months[lambingDate.month - 1]}`;
  }
}
