<ion-app>
  <ion-split-pane [when]="isDesktop" contentId="main-content" class="my-flock-pane">
    <ion-menu id="flockMenuBar" contentId="main-content" type="overlay" class="my-flock-menu" disabled="true">
      <ion-header mode="md" class="ion-no-border">
        <ion-toolbar>
          <ion-title>FLOCKFINDER</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content color="primary">
        <ion-list scroll="false" class="flock-dark-background">
          <ion-menu-toggle *ngIf="isDesktop" auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button routerLink="/manage/home">
              <ion-icon color="light" size="small" name="home" slot="start"></ion-icon>
              <ion-label>Home</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="isDesktop" auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button routerLink="/manage/fields">
              <ion-icon color="light" size="small" name="location" slot="start"></ion-icon>
              <ion-label>Field View</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="isDesktop" auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button routerLink="/manage/flock">
              <ion-icon color="light" size="small" [src]="flockImg" slot="start"></ion-icon>
              <ion-label>Livestock</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="isDesktop" auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button routerLink="/manage/medical">
              <ion-icon color="light" size="small" name="bandage" slot="start"></ion-icon>
              <ion-label>Treatments</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="isDesktop" auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button (click)="navManage('/manage/data')">
              <ion-icon color="light" size="small" name="cloud-upload" slot="start"></ion-icon>
              <ion-label>Data Management</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item
              color="primary"
              lines="none"
              detail="false"
              button
              (click)="navManage('/manage/medical/medical-cabinet')"
            >
              <ion-icon color="light" size="small" name="folder" slot="start"></ion-icon>
              <ion-label>Medicine Cabinet</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item
              color="primary"
              lines="none"
              detail="false"
              button
              (click)="navManage('/manage/medical/suppliers')"
            >
              <ion-icon color="light" size="small" name="bandage" slot="start"></ion-icon>
              <ion-label>Medical Suppliers</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button (click)="navManage('/manage/labels')">
              <ion-icon color="light" size="small" name="pricetag" slot="start"></ion-icon>
              <ion-label>Manage Labels</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item
              color="primary"
              lines="none"
              detail="false"
              button
              (click)="navManage('/manage/movement-history')"
            >
              <ion-icon color="light" size="small" name="swap-horizontal-outline" slot="start"></ion-icon>
              <ion-label>Movement History</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="!isDesktop" auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button routerLink="/account">
              <ion-icon color="light" size="small" name="person" slot="start"></ion-icon>
              <ion-label>Account</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle *ngIf="!isDesktop" auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button (click)="onLogout()">
              <ion-icon color="light" size="small" name="exit" slot="start"></ion-icon>
              <ion-label>Logout</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="isMobile && !isIOS" auto-hide="false">
            <ion-item
              color="primary"
              lines="none"
              detail="false"
              button
              (click)="goToFlockFinderWeb()"
              class="ion-margin-top"
            >
              <ion-icon color="light" size="small" name="cloud" slot="start"></ion-icon>
              <ion-label>FlockFinder on the web</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button (click)="goToAsk()">
              <ion-icon color="light" size="small" name="chatbubbles" slot="start"></ion-icon>
              <ion-label>Ask FlockFinder</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list scroll="false" class="flock-dark-background">
          <ion-menu-toggle *ngIf="isDesktop" auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button routerLink="/account">
              <ion-icon color="light" size="small" name="person" slot="start"></ion-icon>
              <ion-label>Account</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle *ngIf="isDesktop" auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button (click)="onLogout()">
              <ion-icon color="light" size="small" name="exit" slot="start"></ion-icon>
              <ion-label>Logout</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer class="ion-no-border ion-text-center">
        <ion-toolbar color="primary">
          <ion-text
            >Got a suggestion? let us know at:
            <a href="mailto:hello@flockfinder.co.uk" class="flock-light-text"> hello&#64;flockfinder.co.uk </a>
          </ion-text>
        </ion-toolbar>
        <ion-toolbar color="primary" class="ion-text-left flock-small-font ion-no-margin">
          v{{ appVersion }}
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
